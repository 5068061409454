section.uslugi .uslugi-wrap.wentylacja div.level.level_3 {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;

    
    > div {
        justify-self: center;
    }
}
section.glowna {
    height: calc(100vh - var(--mobileNavHeight));
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    padding: 0.9375rem;
    justify-content: space-around;
    
    @media screen and (min-width: 768px) {
        height: calc(100vh - var(--mobileNavHeight));
        padding: 0 3.4375rem 2rem 3.4375rem;
    }

    @media screen and (min-width: 1200px) {
        height: 100vh;
        justify-content: start;
        padding: 8.25rem 3rem 0 3rem;
        position: relative;
    }

    @media screen and (min-width: 1400px) {
        padding: 11.25rem 50% 0 6.25rem;
    }

    h1 {
        font-size: 2rem;
        letter-spacing: -1px;
        line-height: 44px;
        color: #ffffff;
        font-weight: 700;
        font-family: "Montserrat";
        
        @media screen and (min-width: 768px) {
            margin-top: 1rem;
            font-size: 3rem;
            line-height: initial;
        }

        @media screen and (min-width: 1200px) {
            font-size: 2.4rem;
            line-height: 2.4rem;
            margin-top: initial;
        }

        @media screen and (min-width: 1400px) {
            font-size: 4rem;
            line-height: initial;
        }
    }
    img {
        display: block;
        width: 100%;

        @media screen and (min-width: 1200px) {
            width: initial;
            display: initial;
            margin: 40px 0;
        }
    }
    span {
        font-size: 18px;
        color: #ffffff;
        font-weight: 400;
        font-family: "Lato";

        @media screen and (min-width: 768px) {
            font-size: 1.25rem;
        }

        @media screen and (min-width: 1200px) {
            font-size: 18px;
        }

        p {
            margin-bottom: .25rem;

            @media screen and (min-width: 1400px) {
                margin-bottom: 1rem;
            }
        }
    }
    div.wycena {
        border-bottom: 1px solid white;
        margin-bottom: 2.5rem;



        @media screen and (min-width: 1200px) {
            position: absolute;
            top: 5rem;
            right: 3rem;
            border-bottom: 1px solid white;
        }
        @media screen and (min-width: 1400px) {
            top: 11.25rem;
        }

        span {
            p, b {
                display: inline-block;
            }

            @media screen and (min-width: 768px) {
                font-size: 20px;
            }

            @media screen and (min-width: 1200px) {
                p, b {
                    display: initial;
                }
            }
        }
    }
}  

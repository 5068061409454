section.projekty_1 {
    background-color: $main-bg-color;

    .floating-image {
        display: none;

        @media screen and (min-width: 1200px) {
            display: block;
            position: absolute;
            top: var(--mobileNavHeight);
            right: 0;
            z-index: 1;
            max-width: min(23vw, 526px);
            height: min(80vh, 540px);
            object-fit: cover;
            border-bottom-left-radius: 80px;
        }
    }

    div.head {
        position: relative;
        background-color: #333333;

        @media screen and (min-width: 1200px) {
            background-position: top;
            background-size: cover;
            position: relative;
            background-color: #333333;
        }

        @include hero-height;
        
        img {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            width: 150px;
            height: auto;

            @media screen and (min-width: 1200px) {
                position: absolute;
                top: 0;
                right: 0;
                width: 450px;
                height: auto;
            }
        }
        div {
            position: absolute;
            bottom: -3px;
            left: 90px;
            margin-bottom: 0!important;
            left: 50%;
            transform: translateX(-50%);

            @include container-restriction;

            h1 {
                font-family: 'Montserrat';
                font-size: 47px;
                letter-spacing: -2px;
                color: #fff;
                font-weight: 700;
                padding-bottom: 20px;
                margin-bottom: 0;
                width: fit-content;
                border-bottom: 3px solid #28bf64;

                @media screen and (min-width: 1200px) {
                    font-size: 77px;
                    padding-bottom: 50px;
                }
            }
        }
    }
    div.content {
        padding: 0.9375rem;
        
        @include container-restriction;

        @media screen and (min-width: 1200px) {
            margin: 0 auto 100px auto;
        }
        
        div.text {
            @media screen and (min-width: 1200px) {
                margin: 50px 0 100px 0;
                width: 70%;
                text-align: justify;
            }

            h2 {
                font-size: 23px;
                color: #ffffff;
                font-weight: 700;
                font-family: "Lato";
                text-transform: uppercase;
            }
            span {
                font-size: 18px;
                color: #dddddd;
                font-weight: 400;
                font-family: "Lato";
                text-align: justify;
            }
        }
        div.obiekty {
            display: grid;
            grid-template-columns: 1fr;
            column-gap: 2rem;

            @media screen and (min-width: 576px) {
                grid-template-columns: repeat(2, 1fr);
            }
            
            @media screen and (min-width: 1200px) {
                flex-direction: row;
                justify-content: space-between;
                grid-template-columns: repeat(3, 1fr);
                column-gap: 1.625rem;
            }

            a {
                display: block;
                &:hover {
                    text-decoration: none;
                }
                div.obiekt {
                    margin: 0.9375rem 0;

                    @media screen and (min-width: 1200px) {
                        display: flex;
                        flex-direction: column;
                        margin: initial;
                    }

                    div.img {
                        width: 100%;
                        height: 250px;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        border: 1px solid #767676;

                        @media screen and (min-width: 768px) {
                            height: 300px;
                        }

                        @media screen and (min-width: 1200px) {
                            height: 290px;
                        }
                    }
                    p {
                        padding: 10px 0.9375rem;
                        font-size: 19px;
                        color: #ffffff;
                        font-weight: 700;
                        font-family: "Lato";
                        text-align: center;
                        text-transform: uppercase;
                        border: 1px solid #767676;

                        @media screen and (min-width: 1200px) {
                            padding: 2rem 0.85rem;
                        }
                    }
                }
            }
        }
    }
}

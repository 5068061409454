section.uslugi .uslugi-wrap.ogrzewanie-podlogowe {  
    div.level.level_3 {
        display: flex;
        flex-direction: column-reverse;

        @media screen and (min-width: 768px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 3.375rem;
        }

        @media screen and (min-width: 1200px) {
            column-gap: 2rem;
            margin-bottom: 4rem !important;
        }

        .left {
            flex-direction: column;

            img {
                margin-top: 2rem;
            }
        }
    }

    div.wycen {
        text-align: center;
    }
}
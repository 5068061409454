section.slajd {
    display: flex;
    padding: 00.9375rem;

    // max-height: calc(100vh - var(--mobileNavHeight));
    
    @media screen and (min-width: 768px) {
        padding: initial;
        align-items: center;
        max-height: unset;
    }

    @media screen and (min-width: 1200px) {
        min-height: 100vh;
        padding-top: 3.125rem;
    }

    @media screen and (min-width: 1400px) {
        padding-top: 5rem;
    }

    div.box {
        @media screen and (min-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: calc(100vh - var(--mobileNavHeight));
        }
        
        @media screen and (min-width: 1200px) {
            flex-direction: row;
            width: 100%;
            height: 100vh;
        }

        div.content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: initial;
            max-height: calc(100vh - var(--mobileNavHeight) - 1rem);
            // min-height: calc(100vh - var(--mobileNavHeight) - 7rem);
            
            @media screen and (min-width: 768px) {
                flex-direction: column;
                justify-content: space-around;
                align-items: flex-start;
                width: 55%;
                margin-right: auto;
            }
            
            @media screen and (min-width: 1200px) {
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: $main-bg-color;
                position: relative;
                height: fit-content;
                min-height: 70vh;
                max-height: unset;
            }

            @include homepage-slides-left;
            
            div.tresc {
                @media screen and (min-width: 768px) {
                    // width: 50%;
                    padding: 00.9375rem;
                }

                @media screen and (min-width: 1200px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    // height: min(517px, 65vh);
                    padding: initial;
                    width: 100%;
                }
                
                h1.title {
                    font-size: 1.5rem;
                    letter-spacing: -1px;
                    line-height: 2rem;
                    color: #ffffff;
                    font-weight: 700;
                    font-family: "Montserrat";

                    @media screen and (min-width: 768px) {
                        font-size: 2rem;
                        line-height: 54px;
                        margin-top: 0;
                    }
                    
                    @media screen and (min-width: 1200px) {
                        font-size: 2.4rem;
                        line-height: 2.4rem;
                        margin-top: 0px;
                    }

                    @media screen and (min-width: 1400px) {
                        font-size: 53px;
                        line-height: 54px;
                        margin-top: $levelMargin;
                    }
                }

                h1 {
                    @media screen and (min-width: 1200px) {
                        margin-bottom: 35px;
                        font-size: 73px;
                        line-height: 74px;
                        max-width: 500px;
                        margin-top: initial;
                    }
                }
                span {
                    font-size: 0.9375rem;
                    color: #ffffff;
                    font-weight: 400;
                    font-family: "Lato";
                    margin: 0 0 0.9375rem 0;
                    
                    @media screen and (min-width: 768px) {
                        margin: 0.9375rem 0;
                        font-size: 18px;
                    }

                    @media screen and (min-width: 1200px) {
                        margin: 2rem 0;
                        max-width: 500px;
                    }

                    @media screen and (min-width: 1400px) {
                        margin: 60px 0;
                        max-width: 500px;
                    }
                }
                p, span {
                    @media screen and (min-width: 1200px) {
                        display: block;
                    }
                }
                img.partnerzy {
                    width: auto;
                    max-width: 100%;
                    height: auto;
                    display: none;
                    
                    @media screen and (min-width: 768px) {
                        display: block;
                    }
                    
                    @media screen and (min-width: 1200px) {
                        max-width: 70%;
                    }

                    @media screen and (min-width: 1400px) {
                        max-width: 80%;
                    }
                }
                div.ramki {
                    @media screen and (min-width: 1200px) {
                        max-width: 500px;
                    }
                    div.ramka {
                        border: 1px solid #767676;
                        margin: 1px 0;

                        @media screen and (min-width: 1200px) {
                            margin: 3px 0;
                        }

                        span {
                            font-size: 1.0625rem;
                            line-height: 20px;
                            color: #ffffff;
                            font-weight: 400;
                            font-family: "Lato";
                            margin: 4px 0;
                            text-transform: uppercase;
                            display: flex;
                            align-items: center;
                            
                            &::before {
                                content: '';
                                display:inline-block;
                                width: 10px;
                                height: 10px;
                                border: 2px solid white;
                                border-radius: 50%;
                                margin: 00.9375rem;
                                transform: translateY(-1px);
                            }

                            @media screen and (min-width: 1200px) {
                                line-height: 1rem;
                            }
                        }
                    }
                }
            }
            div.kafelki {
                --k-width: 160px;

                display: flex;
                overflow: hidden;
                
                @media screen and (min-width: 768px) {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
                
                @media screen and (min-width: 1200px) {
                    padding-left: 0;
                    padding-right: 0;
                    position: absolute;
                    right: 0;
                    z-index: 1;
                    margin-right: calc(-1 * var(--k-width));
                }
                                
                @media screen and (min-width: 1400px) {
                    --k-width: 217px;
                }

                a:focus, a:link, a:visited {
                    text-decoration: none;
                }

                .left > a:first-child > div {
                    border-top-left-radius: 20px;
                }
                
                .right > a:last-child > div {
                    border-bottom-right-radius: 20px;
                }

                .left > a > div {
                    margin-left: auto;
                }


                div.left, div.right {
                    width: 50%;

                    @media screen and (min-width: 1200px) {
                        width: var(--k-width);
                    }
                }

                div.kafelek {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    height: 6rem;
                    max-width: 192px;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    // animation: z-show 1s ease-in-out;

                    @media screen and (min-width: 1200px) {
                        max-width: initial;
                        width: var(--k-width);
                        height: 140px;
                        text-align: center;
                        align-content: center;
                        cursor: pointer;
                        padding: 0 20px;;
                    }

                    @media screen and (min-width: 1400px) {
                        height: 172px;
                    }

                    img {
                        width: auto;
                        height: 2rem;
                        display: block;
                        
                        @media screen and (min-width: 1200px) {
                            height: 3.4375rem;
                        }
                    }
                    
                    span {
                        display: block;
                        font-size: 0.9375rem;
                        font-weight: 600;
                        font-family: "Montserrat";
                        text-align: center;
                        text-transform: uppercase;

                        @media screen and (min-width: 1200px) {
                            font-size: 1.0625rem;
                        }
                        @media screen and (min-width: 1200px) {
                            font-size: 1.0625rem;
                        }
                    }
    
    
                }

                div.kafelek:not(.last-anim) {
                    span {
                        color: #ffffff;
                    }
                }
            }

            div.partnerzy {
                @media screen and (min-width: 1200px) {
                    position: absolute;
                    left: 100px;
                    bottom: 100px;
                }
            }

            img.partnerzy {
                @media screen and (min-width: 1200px) {
                    display: block;
                    position: absolute;
                    bottom: 0px;
                }
            }
        }

        div.img {
            @media screen and (min-width: 768px) {
                height: 100%;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                right: 0;
            }
                
            @media screen and (min-width: 1200px) {
                background-position: right;
                background-size: cover;
                height: 100vh;
                position: relative;
                top: unset;
                right: unset;
            }

            @include homepage-slides-right;

        }
    }
}
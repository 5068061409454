header.side-header {
    display: none;
}
section.top-menu {
    display: none;
}

section.menu-smartphone {
    display: none;
}

.menu-kontakt-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
        list-style: none;
        padding-left: 0;
        max-width: 125px;
        
        @media screen and (min-width: 1200px) {
            width: 80%;
        }
        
        @media screen and (min-width: 1400px) {
            max-width: 125px;
            width: 100%;
        }
        margin-bottom: 0;
    }
}

@media screen and (min-width: 1200px) {
    header.side-header {
        display: block;
        width: var(--sideheader-width);
        position: fixed;
        top: 0;
        z-index: 200;
        left: 0;
        section.menu {
            z-index: 10;
            display: flex;
            flex-direction: column;
            height: 100vh;
            width: var(--sideheader-width);
            background-color: #1b1b1b;
            color: white;
            
            .logo {
                padding: 20px 30px 0 20px;
            }

            ul {
                margin-top: 3rem;
                list-style: none;
                padding-left: 0;
                
                @media screen and (min-width: 1400px) {
                    margin-top: 6.25rem;
                
                }
                li {
                    padding: 0.9375rem 2.5rem;
                    border-bottom: 1px solid #2f2f2f;
                    
                    &.lang-item {
                        display: inline-flex;
                        padding: 1rem 0.5rem 1.2rem 1.5rem;
                        border-bottom: 0;
                        margin-left: 1rem;

                        img {
                            margin: 0;
                        }
                    }
                    a {
                        font-family: "Montserrat";
                        font-size: 0.9375rem;
                        font-weight: 600;
                        color: #ffffff;
                        text-decoration: none;        
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    section.top-menu {
        display: block;
        background-color: black;
        position: fixed;
        top: 0;
        width: calc(100vw - var(--sideheader-width));
        margin-left: var(--sideheader-width);
        z-index: 2;
        display: flex;
        div.hamburger, div.sociale {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        div.hamburger {
            flex-basis: 100px;
        }
        div.sociale {
            flex-basis: 12%;

            @media screen and (min-width: 1400px) {
                flex-basis: 12rem;
            }
            a {
                width: 20px;
                height: 20px;
                i {
                    font-size: 0.9375rem;
                }
            }
        }
        div.menu-menu-gorne-container {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            ul {
                display: flex;
                justify-content: center;
                margin-bottom: 0;
                width: 100%;
                
                @media screen and (min-width: 1200px) {
                    padding-left: 1rem;
                    padding-right: 1rem;
                    justify-content: space-between;
                }
                
                @media screen and (min-width: 1400px) {
                    justify-content: center;
                }

                li {
                    display: block;
                    margin: 1.875rem 0;
                    padding: 0 1.25rem;
                    
                    @media screen and (min-width: 1200px) {
                        margin: 1rem 0;
                        padding: 0 .5rem;
                    }
                    
                    @media screen and (min-width: 1400px) {
                        margin: 1.875rem 0;
                        padding: 0 1.25rem;
                    }

                    a {
                        font-family: "Montserrat";
                        font-size: .8125rem;
                        font-weight: 600;
                        color: #ffffff;
                        text-decoration: none;
                        text-transform: uppercase;

                        @media screen and (min-width: 1400px) {
                            font-size: 0.9375rem;
                        }
                    }
                }
            }
        }
    
    }
}



@media screen and (max-width: 1199.98px) {
    section.menu-smartphone {
        background-color:  #1b1b1b;
        z-index: 2;
        display: flex;
        display: block;
        position: sticky;
        top: 0;
        flex-direction: column;
        width: 100%;

        div.belka {
            // width: 100%;
            // height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // div.logo img {
            //     height: 80px;
            //     width: auto;
            // }
            div.hamburger-rwd img {
                margin: 0.9375rem 0.9375rem;
            }

            .logo {
                padding-left: 1rem;
                img {
                    max-height: 40px;
                    width: auto;
                }
            }
        }
        div.content {
            position: fixed;
            display: block;
            height: calc(100% - var(--mobileNavHeight));
            width: 100%;
            margin: initial;
            right: -100%;
            transition: .3s right;
            display: flex;
            justify-content: flex-start;
            padding: 2rem;
            top: var(--mobileNavHeight);
            overflow-y: scroll;

            &.show {
                right: 0;
            }

            ul {
                margin-bottom: 0;
                padding-left: 0;

                li {
                    padding: 10px 25px;
                    border-bottom: 1px solid #2a2a2a;
                    list-style-type: none;
                    
                    &.lang-item {
                        display: inline-flex;
                        padding: 0.5rem 0.5rem 0.5rem 1.5rem;
                        border-bottom: 0;
                        
                        img {
                            margin: 0;
                        }
                    }
                    a {
                        font-family: "Montserrat";
                        font-size: 0.9375rem;
                        font-weight: 600;
                        color: #ffffff;
                        text-decoration: none;        
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}


section.uslugi .uslugi-wrap.samochody {
    div.level.level_3 {
        @include alternative-level-3;
        margin-bottom: $levelMargin;

        .right .img {
            @include flex-center;
        }
    }

    
    div.level.level_4 {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: $levelMargin;
        
        @media screen and (min-width: 1200px) {
            display: block;
            // padding-bottom: 24rem !important;
        }

        .text {
            @media screen and (min-width: 1200px) {
                position: absolute;
                width: min(50%, 650px);
                position: absolute;
                top: 0;
                left: 0;
                padding: 0 2rem 1rem 0;
                background: $main-bg-color;
            }
        }

        img {
            width: 100%;
        }
    }

}
section.uslugi div.content {
    &.klimatyzacja {
        @media screen and (min-width: 768px) {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
        div.level {
            &.level_1 {
                flex-direction: column-reverse;
                margin-bottom: 20px;

                @media screen and (min-width: 1200px) {
                    grid-template-columns: repeat(2, 1fr);
                    div.left {
                        max-width: 632px;
                    }
                }

                div.right {
                    width: 100%;

                    div.klimatyzacja_w_domu {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 2rem 0;

                        @media screen and (min-width: 768px) {
                            padding: 0 35px;
                        }
                        
                        @media screen and (min-width: 1200px) {
                            padding: 3rem 2rem;
                            border-bottom-left-radius: 50px;
                            border-top-right-radius: 50px;
                        }

                        p.klimatyzacja_w_domu {
                            font-size: 30px;
                            line-height: 47px;
                            color: #ffffff;
                            font-weight: 800;
                            font-family: "Montserrat";
                            width: 70%;
                            margin-bottom: 0;

                            @media screen and (min-width: 768px) {
                                margin-top: $levelMargin;
                                margin-bottom: $levelMargin;
                                font-size: 40px;
                                width: 100%;
                            }

                        }
                    }
                }
            }
            &.level_2 {
                flex-direction: column;

                div.left {
                    margin-bottom: 20px;
                }
                div.right {
                    &.klimatyzacja {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        div.klimatyzatory {
                            text-align: center;
                            img {
                                width: 95%;
                                height: auto;
                                margin: 20px 5px;

                                @media screen and (min-width: 1200px) {
                                    width: 100%;
                                    height: initial;
                                    margin: initial;
                                    margin-top: 20px;
                                }
                            }
                        }
                    }
                }
            }
            &.level_3 {
                display: flex;

                @media screen and (min-width: 1200px) {
                    flex-direction: row;
                }
                div {
                    width: 100%;
                    height: 100px;
                    margin: 20px 0;

                    @media screen and (min-width: 1200px) {
                        width: 100%;
                        height: 200px;
                        margin: initial;
                    }
                }
            }
            &.level_4 {
                flex-direction: column;
                justify-content: space-between;

                @media screen and (min-width: 1200px) {
                    display: grid;
                    grid-template-columns: 5fr 6fr;
                    column-gap: 2rem;
                }

                .right {
                    align-items: center;
                    justify-content: flex-end;
    
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

section.uslugi .uslugi-wrap.klimatyzacja {
    .wycen {
        text-align: center;
    }


}
 div.level.level_4 {
    grid-template-columns: 1fr;
    
    > div {
        justify-self: center;
    }
}
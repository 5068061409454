@mixin formInput {
    width: 100%;
    color: white;
    background: black;
    font-size: 1.0625rem;
    padding: .875rem 1.125rem;
    border: 1px solid $border-color;
    margin-bottom: .375rem;

    &::placeholder {
        color: white;
    }
}

.wpcf7-form {
    input:not([type=submit]) {
        @include formInput;
    }

    textarea {
        @include formInput;
        margin-bottom: 0;
        height: 14.675rem;
    } 

    select {
        @include formInput;

        appearance: none;
        background: url('../../../img/arrow_down.png') no-repeat;
        background-position: 97% center;

        option {
            background: black;
            color: white;
        }

    }

    .row:last-child {
        margin-top: 2.5rem;
    }

    .wpcf7-list-item {
        margin-left: 0;
        color: white;

        label {
            display: grid;
            grid-template-columns: 16px auto; 
            column-gap: 1rem;

            > span {
                position: relative;
                top: -4px;
            }
        }

        input[type=checkbox] {
            appearance: none;
            width: 16px;
            height: 16px;
            border: 1px solid $border-color;
            background: transparent;
            padding: 0;

            &:checked {
                background: $border-color;
            }
        }
    }
    .wpcf7-response-output {
        color: white;
    }

    .wpcf7-form-control-wrap {
        width: 100%;
    }

    .row.first {
        div {
            padding: 0;
        }
    }
    
    @media screen and (min-width: 992px) {
        .row.first {
            > div:first-child {
                padding-right: 2rem;
            }
            > div:last-child {
                padding-left: 2rem;
            }
        }
    }

    .wpcf7-list-item-label {
        color: #868686;
    }

    .submit-wrap {
        position: relative;

        input {
            background: #005da5;
            color: white;
            font-family: 'Lato';
            font-weight: bold;
            padding: 0.5rem 4.8rem 0.5rem .8rem;
            font-size: 1.1875rem;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border: 0;
            background-image: url('../../../img/button-arrow-head.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right;
            
            @media screen and (min-width: 768px) {
                padding: 0.7rem 5.8rem 0.7rem 1.8rem;
            }
        }

        // &::after {
        //     background: #0094f2;
        //     width: 40px;
        //     height: 100%;
        //     display: block;
        //     content: "";
        //     position: relative;
        //     top: 0;
        //     right: 0;
        //     position: absolute;
        // }
    }
}

section.kontakt {
    background-color: $main-bg-color;
    div.head {
        background-position: top;
        background-size: cover;
        position: relative;

        @include hero-height;
        
        div {
            position: absolute;
            bottom: -3px;
            margin-bottom: 0!important;
            left: 50%;
            transform: translateX(-50%);

            @include container-restriction;

            h1 {
                font-family: 'Montserrat';
                font-size: 47px;
                letter-spacing: -2px;
                color: #fff;
                font-weight: 700;
                padding-bottom: 20px;
                margin-bottom: 0;
                width: fit-content;
            }
        }
    }
    div.content {
        display: flex;
        flex-direction: column;
        margin: 0 auto 0 auto;
        padding: 0 10px;

        @include container-restriction;

        div.level {
            padding: 20px 0!important;
            border-bottom: 1px solid #363738;
            &.adres {

                flex-direction: column;

                @media screen and (min-width: 768px) {
                    flex-direction: row;
                }

                h2 {
                    font-size: 20px;
                    line-height: 22px;
                    color: #ffffff;
                    font-weight: 700;
                    font-family: "Lato";
                    text-align: left;
                }
                span {
                    font-size: 16px;
                    line-height: 22px;
                    color: #ffffff;
                    font-weight: 400;
                    font-family: "Lato";
                    text-align: left;
                }
                div.kostki {
                    display: grid;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin: 20px 0;
                    
                    @media screen and (min-width: 576px) {
                        display: flex;
                        justify-content: flex-start;
                    }
                    
                    div {
                        width: fit-content;
                        padding: .75rem;

                        @media screen and (min-width: 768px) {
                            padding: 1.5625rem 1.875rem;
                        }
                        &.telefon {
                            border-top-left-radius: 0.9375rem;
                            border-bottom-right-radius: 0.9375rem;
                            span {
                                font-size: 24px;
                                line-height: 31px;
                                color: #ffffff;
                                font-weight: 700;
                                font-family: "Lato";
                                text-align: left;
                            }
                        }
                        &.email {
                            border-top-right-radius: 0.9375rem;
                            border-bottom-left-radius: 0.9375rem;
                            span {
                                font-size: 24px;
                                line-height: 31px;
                                color: #ffffff;
                                font-weight: 400;
                                font-family: "Lato";
                                text-align: left;
                            }
                        }
                    }
                }
            }
            &.dzialy {
                display: grid;
                grid-template-columns: 1fr;
                column-gap: 1.5rem;
                
                @media screen and (min-width: 576px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                
                @media screen and (min-width: 1200px) {
                    grid-template-columns: repeat(3, 1fr);
                }
                @media screen and (min-width: 1400px) {
                    grid-template-columns: repeat(4, 1fr);
                }
                
                div.dzial {
                    display: grid;
                    grid-template-columns: 64px auto;
                    column-gap: 1rem;

                    margin: 10px 0;
                    text-align: left;
                    
                    @media screen and (min-width: 768px) {
                        display: flex;
                        flex-basis: unset;
                    }


                    div.text {
                        display: flex;
                        flex-direction: column;
                        
                        span {
                            display: block;
                            font-size: 1rem;
                            line-height: 1.3;
                            color: #ffffff;
                            font-family: "Lato";
                            font-weight: 400;
                            
                            @media screen and (min-width: 768px) {
                                font-size: 1.1875rem;
                                line-height: 1.375rem;
                            }
                            
                            a {
                                color: #ffffff!important;
                            }
                        }
                    }
                }
            }
        }
    }
    div.map {
        height: 400px;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

@media only screen and (min-width: 768px) {
    section.kontakt {
        div.head {
            div {
                // width: 700px;
            }
        }
        div.content {
            // width: 700px;
            padding: initial;
            div.level {
                &.dzialy {
                    div.dzial {
                        // width: 50%;
                        div.text {
                            span {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (min-width: 1200px) {
    section.kontakt {
        background-color: $main-bg-color;
        div.head {
            background-position: top;
            background-size:cover;
            position: relative;

            @include hero-height;

            div {
                position: absolute;
                bottom: -3px;
                margin-bottom: 0!important;
                // width: 1000px;
                // left: 50%;
                transform: translateX(-50%);

                @include container-restriction;

                h1 {
                    font-family: 'Montserrat';
                    font-size: 77px;
                    letter-spacing: -2px;
                    color: #fff;
                    font-weight: 700;
                    padding-bottom: 50px;
                    margin-bottom: 0;
                    width: fit-content;
                }
            }
        }
        div.content {
            // width: 1000px;
            margin: 50px auto 0 auto;
            div.level {
                padding: 50px 0!important;
                &.adres {
                    span {
                        font-size: 19px;
                    }
                    div.kostki {
                        div {
                            &.email {
                                border-top-right-radius: 0.9375rem;
                                border-bottom-left-radius: 0.9375rem;
                            }
                        }
                    }
                }
                &.dzialy {
                    div.dzial {
                        // width: 310px;
                    }
                }
            }
        }
        div.map {
            height: 400px;
        }
    }
}

@media only screen and (min-width: 1920px) {
    section.kontakt {
        div.head div {
            max-width: $desktop-width;
            width: 100%;
        }
        div.content {
            max-width: $desktop-width;
            width: 100%;
            div.level.dzialy {
                div.dzial {
                    width: initial;
                }
            }
        }
    }
}



section.certyfikaty {
    background-color: $main-bg-color;
    div.head {

        @include hero-height;
        
        h1 {
            border-bottom: 3px solid #0078dd;
            text-transform: capitalize;
        }
    }
    
    div.content {
        display: grid;
        justify-content: flex-start;
        grid-template-columns: 1fr;
        row-gap: 2rem;
        column-gap: 2rem;

        @include container-restriction;

        @media screen and (min-width: 576px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (min-width: 1200px) {
            margin: 50px auto 100px auto;
            justify-content: space-between;
            grid-template-columns: repeat(4, 1fr);
        }

        a {
            display: block;
            position: relative;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    
            div.magnifier {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 40px;
                height: 40px;
                background-color: #333333;
                border: 1px solid #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
    
                & > img {
                    display: block;
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

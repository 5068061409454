div.content {
    display: flex;
    flex-direction: column;
    margin: 20px auto 0 auto;
    background-color: $main-bg-color;

    div.oferta {
        div.stanowisko {
            display: grid;
            grid-template-columns: auto 80px;
            justify-content: space-between;
            width: 100%;
            border: 1px solid rgba(255,255,255,0.42);
            padding: 5px 10px;
            cursor: pointer;
            margin: 0 0 20px 0;
            
            @media screen and (min-width: 768px) {
                
                display: flex;
            }

            h2 {
                font-size: 18px;
                color: #ffffff;
                font-weight: 700;
                font-family: "Lato";
                margin: 0;
                text-transform: uppercase;
                line-height: 50px;
            }
            div.zwin {
                text-align: center;
                white-space : nowrap;
                span {
                    font-size: 0.9375rem;
                    line-height: 44px;
                    color: #ffffff;
                    font-weight: 400;
                    font-family: "Lato";
                    margin-right: 5px;
                }
            }
        }
        div.tresc {
            position: relative;
            padding: 0 10px;
            div.img {
                display: block;
                // border-radius: 20px;
                overflow: hidden;
                width: 100%;
                img {
                    height: auto;
                    width: 100%;
                }
            }
            section {
                margin: 0.9375rem 0 40px 0;
                
                h3 {
                    font-size: 23px;
                    line-height: 25px;
                    color: #ffffff;
                    font-weight: 400;
                    font-family: "Lato";
                }
                ul {
                    font-size: 1.0625rem;
                    line-height: 21px;
                    color: #ffffff;
                    font-weight: 400;
                    font-family: "Lato";
                    list-style: none;
                    padding-left: 10px;
                    li {
                        margin-left: -10px;
                        &::before {
                            content: '';
                            display:inline-block;
                            width: 10px;
                            height: 10px;
                            background-color: white;
                            border: 2px solid red;
                            border-radius: 50%;
                            margin: 0 10px;
                            transform: translateY(-1px);
                        }
                    }
                }
                span {
                    font-size: 19px;
                    letter-spacing: 0px;
                    color: #ffffff;
                    font-weight: 400;
                    font-family: "Lato";
                    .gold {
                        color: #f7b20a;
                    }
                }
            }
        }
    }
}

section.oferty {
    div.content {
        @include container-restriction;
    }
}

@media only screen and (min-width: 786px) {
}

@media only screen and (min-width: 1200px) {
    section.oferty {
        div.content {
            margin: 100px auto 0 auto;
            div.oferta {
                div.stanowisko {
                    h2 {
                        font-size: 23px;
                        line-height: 40px;
                    }
                    div.zwin {
                        text-align: initial;
                    }
                }
                div.tresc {
                    position: relative;
                    padding: initial;
                    div.img {
                        position: absolute;
                        display: initial;
                        width: fit-content;
                        top: 80px;
                        right: 0;
                        border-radius: 20px;
                        overflow: hidden;
                        img {
                            height: initial;
                            width: initial;
                        }
                    }
                    section {
                        margin: initial;
                        margin-bottom: 40px;
                        ul {
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
/*
Theme Name: custom
Version: 1.0
*/

@import "variables.scss";

img {
    max-width: 100%;
    height: auto;
}

:root {
    --topMenuHeight: 84;
    --sideheader-width: 300px;
    --mobileNavHeight: 3.6875rem;
    
    @media screen and (min-width: 1200px) {
        --sideheader-width: 250px;
    }
    
    @media screen and (min-width: 1400px) {
        --sideheader-width: 300px;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    background-color: $main-bg-color !important;
    color: white;
    // height: 200vh;
    &::-webkit-scrollbar {
        display: none;
    }
}

.btn_contact_us_lnk {
    color: white;
  
    &:hover {
        color: #f2f2f2;
    }
}

.btn_contact_us {
    width: fit-content;
    background-color: #00a5e4;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    margin: 3rem auto 0 auto;
    padding: 0.5rem 2rem;
    font-size: 1.25rem;
    font-weight: 700;

}

span {
    display: inline-block;
}

div.wrapper {
    display: flex;
    flex-direction: column;
    margin: 0px 0 0 0;
}
img.wycen {
    width: 99%;
    height: 58px;
}
.br-tl-100 {
    overflow: hidden;
    border-top-left-radius: 50px;
}
.br-tr-100 {
    overflow: hidden;
    border-top-right-radius: 50px;
}
.br-bl-100 {
    overflow: hidden;
    border-bottom-left-radius: 50px;
}
.br-br-100 {
    overflow: hidden;
    border-bottom-right-radius: 50px;
}

.br-tl-50 {
    overflow: hidden;
    border-top-left-radius: 50px;
}
.br-tr-50 {
    overflow: hidden;
    border-top-right-radius: 50px;
}
.br-bl-50 {
    overflow: hidden;
    border-bottom-left-radius: 50px;
}
.br-br-50 {
    overflow: hidden;
    border-bottom-right-radius: 50px;
}

.br-tl-25 {
    overflow: hidden;
    border-top-left-radius: 25px;
}
.br-tr-25 {
    overflow: hidden;
    border-top-right-radius: 25px;
}
.br-bl-25 {
    overflow: hidden;
    border-bottom-left-radius: 25px;
}
.br-br-25 {
    overflow: hidden;
    border-bottom-right-radius: 525x;
}

@media screen and (min-width: 768px) {
    .br-tl-100 {
        overflow: hidden;
        border-top-left-radius: 100px;
    }
    .br-tr-100 {
        overflow: hidden;
        border-top-right-radius: 100px;
    }
    .br-bl-100 {
        overflow: hidden;
        border-bottom-left-radius: 100px;
    }
    .br-br-100 {
        overflow: hidden;
        border-bottom-right-radius: 100px;
    }
    img.wycen {
        width: initial;
    }
}

@media screen and (min-width: 1200px) {
    div.wrapper {
        margin: 0px 0 0 var(--sideheader-width);
    }
    img.wycen {
        width: 336px;
        height: 58px;
    }

}
  
a, a:link, a:hover, a:active, a:visited {
    text-decoration: none!important;
}

@keyframes z-show {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

@keyframes text-color {
    from {
        color: white;
        font-weight: normal;
    }
    
    to {
        color: gray;
        font-weight: bold;
    }
}

.swiper-slide-active .kafelek.last-anim {
    animation: z-show 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards, text-color 2s infinite alternate;
}

.kafelek.last-anim {
    transform: scale(0);
}


div.swiper-slide {
    // .slajd {
    //     max-height: calc(100vh - var(--mobileNavHeight));
    // }


    @media screen and (min-width: 768px) {
        .slajd {
            max-height: unset;
        }
    }

    @media screen and (min-width: 1200px) {
        min-height: 100vh;
        max-height: unset;

        .slajd {
            padding-top: initial;
        }
    }
}

.col, .row {
    padding: 0!important;
    margin: 0!important;
}


.list_content{ 
    max-height: 0; 
    overflow: hidden; 
    transition: max-height .4s ease;
}
@media only screen and (min-width: 280px) {
    .list_content.-show { 
        max-height: 2000px;
    }
}

@media only screen and (min-width: 1200px) {
    .list_content.-show { 
        // max-height: 900px;
    }
}

div.swiper_wrapper {
    display: flex;
    position: fixed; 
    flex-direction: row;
    align-items: flex-end;
    left: 35px; 
    bottom: 35px; 
    z-index: 2; 
    width: 200px; 
    // height: 200px;
    .swiper-pagination-vertical.swiper-pagination-bullets {
        // left: 20px;
        top: auto;
        transform: none;
        right: auto;

        .swiper-pagination-bullet {
            margin-top: .45rem;
            
            @media screen and (min-width: 1400px) {
                margin-top: .75rem;
            }
        }
    }
    span.swiper-pagination-bullet {
        background-color: #8b8b8b;
        width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,12px));
        height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,12px));

    }
    span.swiper-pagination-bullet-active {
        background-color: white;
    }
    
    div.counter span {
        padding-left: 32px;
        font-size: 3rem;
        line-height: 1rem;
        color: #f2f2f2;
        font-weight: 275;
        font-family: "Montserrat";
        
        @media screen and (min-width: 1200px) {
            font-size: 6rem;
        }
        
    }
}





.swiper {
    width: 100% !important;
    height: calc(100vh - var(--mobileNavHeight)) !important;
    // margin-top: var(--mobileNavHeight);
    
    .swiper-slide {
        overflow-y: hidden;
    }
        
    @media screen and (min-width: 768px) {
        height: calc(100vh - var(--mobileNavHeight)) !important;
    }   
    @media screen and (min-width: 1200px) {
        // margin-top: 0;
        height: 100vh !important;
    }
}

div.head {
    background-position: top;
    background-size: cover;
    height: 25vh;
    position: relative;
    display: flex;
    justify-content: center;

    @include hero-height;

    div {
        position: absolute;
        bottom: -3px;
        margin-bottom: 0!important;

        @include container-restriction;

        h1 {
            font-family: 'Montserrat';
            font-size: 37px;
            letter-spacing: -2px;
            color: #fff;
            font-weight: 700;
            padding-bottom: 20px;
            margin-bottom: 0;
            width: fit-content;
        }
    }
}

div.head.type_a {
    background-color: #333333;
    width: 100%;
    
    @include hero-height;
    
    div {
        margin-bottom: 0!important;
        left: 50%;
        transform: translateX(-50%);

        @include container-restriction;

        h1 {
            font-size: 47px;
            padding-bottom: 20px;
        }
    }
}

img.scale-cover {
    object-fit: cover;
}

@media only screen and (min-width: 768px) {
    div.head.type_a {
        height: 25vh;
        div {
            width: 700px;
            h1 {
                font-size: 77px;
                padding-bottom: 20px;
            }
        }
    }
    div.div_myszka {
        display: block;
        position: fixed;
        bottom: 20px;
        left: 50%;
        z-index: 12;
        cursor: pointer;
    }
    
}

@media only screen and (min-width: 1200px) {
    div.head.type_a {
        height: 35vh;
        background-color: #333333;
        display: flex;
        justify-content: center;
        div {
            margin-bottom: 0!important;
            left: initial;
            transform: initial;

            @include container-restriction;

        }
        h1 {
            font-size: 77px;
            padding-bottom: 50px;
        }
    }
}    

.mg-button {
    font-size: 1.1875rem;
    color: white;
    border: 0;
    background: url('../../img/button-arrow-head.png') no-repeat;
    background-size: contain;
    background-position: right;
    background-color: #005da5;
    padding: 0.5rem 1.25rem;
    padding-right: calc(1.35rem + 56px);
    font-family: 'Lato';
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;

}

body > .orientation-warning {
    display: none;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;

    > div {
        font-size: 1.25rem;
        width: 90%;
        text-align: center;
    }
}

@media screen and (orientation: landscape) and (max-width: 991.98px) {
    body > * {
        display: none !important;
    }

    body > .orientation-warning {
        display: flex !important;
    }
}

.menu-item a img, img.menu-image-title-after, img.menu-image-title-before, img.menu-image-title-above, img.menu-image-title-below, .menu-image-hover-wrapper .menu-image-title-above {
    max-height: 22px;
}

@import "parts/certyfikaty.scss";
@import "parts/glowna.scss";
@import "parts/kontakt.scss";
@import "parts/menu.scss";
@import "parts/oferty-pracy.scss";
@import "parts/partnerzy.scss";
@import "parts/projekty.scss";
@import "parts/realizacje.scss";
@import "parts/single.scss";
@import "parts/slajd.scss";
@import "parts/uslugi.scss";

//overrides for specific services pages
@import "parts/uslugi-pompy-ciepla.scss";
@import "parts/uslugi-samochody-elektryczne.scss";
@import "parts/uslugi-wentylacja.scss";
@import "parts/uslugi-klimatyzacja.scss";

@import "parts/uslugi-fotowoltaika.scss";
@import "parts/uslugi-instalacje-wod-kan.scss";
@import "parts/uslugi-ogrzewanie-podlogowe.scss";
// @import "parts/uslugi-instalacje-gazowe.scss";
// @import "parts/uslugi-kotly-gazowe.scss";
// @import "parts/uslugi-energia-wiatrowa.scss";

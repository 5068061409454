section.single {
    background-color: $main-bg-color;
    padding-bottom: 50px;

    @media screen and (min-width: 768px) {
        padding-bottom: 100px;
    }

    div.head {
        height: 25vh;
        position: relative;
        background-color: #333333;

        @media screen and (min-width: 1200px) {
            height: 35vh;

            div {
                position: absolute;
                bottom: -3px;
                left: 90px;
                margin-bottom: 0 !important;
                left: 50%;
                transform: translateX(-50%);

                @include container-restriction;

                h1 {
                    font-size: 77px;
                    padding-bottom: 50px;
                }
            }
        }

        h1 {
            font-family: "Montserrat";
            font-size: 47px;
            letter-spacing: -2px;
            color: #fff;
            font-weight: 700;
            padding-bottom: 20px;
            margin-bottom: 0;
            width: fit-content;
            border-bottom: 3px solid #28bf64;
        }
    }
    div.projekty_2 {
        margin: 0 auto;

        @include container-restriction;

        h2.head {
            font-size: 23px;
            color: #ffffff;
            font-weight: 700;
            font-family: "Lato";
            text-align: center;
            text-transform: uppercase;
            margin: 23px 0;

            @media screen and (min-width: 768px) {
                font-size: 23px;
                margin: 33px 0;
            }

            @media screen and (min-width: 1200px) {
                margin: 73px 0;
            }
        }
        div.obiekty {

            @media screen and (min-width: 768px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 3rem;
            }

            a {
                margin-bottom: 60px;
                &:hover,
                &:focus,
                &:link {
                    text-decoration: none;
                }
                div.obiekt {
                    display: flex;
                    flex-direction: column;
                    border: 1px solid $border-color;
                    position: relative;
                    margin: 00.9375rem;
                    height: 100%;


                    @media screen and (min-width: 768px) {
                        margin: initial;
                    }

                    div.img {
                        border-bottom: 1px solid $border-color;
                        flex-grow: 1;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                    h2 {
                        height: 100px;
                        font-size: 20px;
                        color: #ffffff;
                        font-weight: 700;
                        font-family: "Lato";
                        margin: 12px 0 0 12px;
                        text-transform: uppercase;

                        @media screen and (min-width: 768px) {
                            margin: 22px 0 0 22px;
                        }
                    }
                    div.details {
                        position: absolute;
                        bottom: 10px;
                        right: 20px;

                        @media screen and (min-width: 768px) {
                            bottom: 0.9375rem;
                            right: 20px;
                        }
                        span {
                            font-size: 0.9375rem;
                            line-height: 44px;
                            color: #ffffff;
                            font-weight: 400;
                            font-family: "Lato";
                        }
                    }
                }
            }
        }
    }
    div.projekty_3 {
        figure {
            display: none;
        }
        div.text {
            margin: 0 auto;

            @include container-restriction;
            
            h1 {
                font-size: 23px;
                color: #ffffff;
                font-weight: 700;
                font-family: "Lato";
                text-transform: uppercase;
                padding-bottom: 0.9375rem;
                border-bottom: 1px solid #363738;
                margin: 20px 0 25px 0.9375rem;

                @media screen and (min-width: 768px) {
                    margin-top: 50px;
                    margin-bottom: 35px;
                }

                @media screen and (min-width: 1200px) {
                    margin: 70px 0 55px 0;
                }
            }
            div.content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 00.9375rem;

                @media screen and (min-width: 1200px) {
                    margin: initial;
                    padding: initial;
                    display: grid;
                    grid-template-columns: 6fr 4fr;
                    column-gap: 3rem;
                }

                div.left {
                    color: #dddddd;

                    p {
                        font-size: 18px;
                        font-weight: 400;
                        font-family: "Lato";
                        text-align: left;
                        text-align: justify;
                        margin-bottom: 0;
                        &:not(:first-child) {
                            margin-top: 30px;
                            padding-top: 30px;
                            border-top: 1px solid #363738;
                        }
                    }
                }
                div.right {
                    display: flex;
                    flex-direction: column;

                    div.top {
                        margin: 0 0 0.9375rem 0;

                        @media screen and (min-width: 1200px) {
                            margin-bottom: 1.0625rem;
                        }

                        a img {
                            width: 100%;
                            height: 200px;
                            object-fit: cover;

                            @media screen and (min-width: 768px) {
                                height: 400px;
                            }

                            @media screen and (min-width: 1200px) {
                                // width: 519px;
                                height: 324px;
                            }
                        }
                    }
                    div.bottom {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        row-gap: 1rem;
                        column-gap: 1rem;

                        a {
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

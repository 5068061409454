section.uslugi div.content.instalacje-wod-kan div.level {
    &.level_1 {
        @media screen and (min-width: 1200px) {
            grid-template-columns: 2fr 1fr;
        }

        .left {
            max-width: unset;
        }
    }

    &.level_2 {
        img.wycen {
            max-width: 336px;
            width: 100%;
            margin-top: 4rem;
        }
    }
}
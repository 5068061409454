$border-color: #767676;
$main-bg-color: #121212;
$desktop-width: min(calc(100vw - var(--sideheader-width) - 2rem), 1300px);
$levelMargin: 3.125rem;

@mixin container-restriction {
    width: 100%;
    max-width: calc(100vw - 2rem);
    
    @media screen and (min-width: 1200px) {
        max-width: $desktop-width
    }
}

@mixin alternative-level-3 {
    grid-template-columns: 1fr;
    max-width: unset;
    
    > div:first-child {
        justify-self: flex-start;
    }
    > div:last-child {
        justify-self: flex-end;
    }
    
    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

@mixin homepage-slides-right {
    @media screen and (min-width: 768px) {
        width: 45%;
    }
    @media screen and (min-width: 1200px) {
        width: 25%;

    }
    @media screen and (min-width: 1400px) {
        width: 37%;

    }
}

@mixin homepage-slides-left {
    @media screen and (min-width: 1200px) {
        width: 75%;
        padding: 0 0 0 3rem;
    }
    @media screen and (min-width: 1400px) {
        width: 63%;
        padding: 0 0 0 6.25rem;
    }
}

@mixin hero-height {
    height: 25vh;
    
    @media screen and (min-width: 768px) {
        height: 15rem;
    }

    @media screen and (min-width: 1200px) {
        height: 300px;
    }
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}


@mixin level-1 {
    display: grid;
    row-gap: 2rem;
    margin-bottom: 3rem;
    column-gap: 2rem;

    @media screen and (min-width: 768px) {
        align-items: center;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 2rem;
        margin: $levelMargin 0;
    }

    @media screen and (min-width: 1200px) {
        margin-top: 0;
        column-gap: 5rem;
    }
}

@mixin first-section-header {
    // line-height: 60px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Montserrat";
    text-align: start;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    
    @media screen and (min-width: 768px) {
        font-size: 2.125rem;
        margin-bottom: 3.125rem;
    }
}
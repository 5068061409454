section.partnerzy {
    display: flex;
    flex-direction: column;
    background-color: $main-bg-color;
    min-height: 100vh;
    div.content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        // min-height: 100vh;
        padding: 00.9375rem;
        margin: 0 auto;
        
        h2.title {
            font-size: 1.5rem;
            line-height: 1.4;
            color: #ffffff;
            font-weight: 700;
            font-family: "Lato";
            text-align: left;
            text-transform: capitalize;
            position: relative;
            margin: .75rem 0 .75rem 0;
            
            &::after {
                content:'';
                display:inline-block;
                width:100%; 
                height:100%;
                border-bottom:1px solid #5b5b5b;
                position: absolute;
                top: -30%;
                margin: 0 30px;
                width: 100px;
            }
            
            @media screen and (min-width: 768px) {
                font-size: 1.75rem;
                margin: 1.25rem 0;
            }
        }

        div.teleadres {
            display: flex;
            flex-direction: column;
            
            span.adres {
                font-size: 0.9375rem;
                line-height: 22px;
                color: #ffffff;
                font-weight: 400;
                font-family: "Lato";
                text-align: left;
                
                @media screen and (min-width: 768px) {
                    font-size: 1.1875rem;
                }
            }
            
            div.kostki {
                div {
                    width: fit-content;
                    
                    @media screen and (min-width: 768px) {
                        padding: 0.9375rem 0.9375rem;
                    }
                    
                    &.telefon {
                        border-top-left-radius: 0.9375rem;
                        border-bottom-right-radius: 0.9375rem;
                        
                        @media screen and (min-width: 768px) {
                            background-color: #f34213;
                        }

                        span {
                            font-size: 1rem;
                            line-height: 1;
                            color: #ffffff;
                            font-weight: 700;
                            font-family: "Lato";
                            text-align: left;
                            
                            @media screen and (min-width: 768px) {
                                font-size: 1.75rem;
                                line-height: 31px;
                            }
                        }
                    }

                    &.email {
                        border-top-right-radius: 0.9375rem;
                        border-bottom-left-radius: 0.9375rem;
                        
                        @media screen and (min-width: 768px) {
                            background-color: #f38113;
                        }
                        
                        span {
                            font-size: 1rem;
                            line-height: 1;
                            color: #ffffff;
                            font-weight: 400;
                            font-family: "Lato";
                            text-align: left;

                            @media screen and (min-width: 768px) {
                                font-size: 1.75rem;
                                line-height: 31px;
                            }
                        }
                    }
                }
            }
        }
        div.partnerzy {
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    div.map {
        height: 200px;
        display: none;

        @media screen and (min-width: 768px) {
            display: block;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

@media only screen and (min-width: 768px) {
    section.partnerzy {
        justify-content: space-between;
        div.content {
            div.teleadres {
                flex-direction: row;
                justify-content: space-between;
            }
        }
        div.map {
            height: 500px;
        }
    }
}

@media only screen and (min-width: 1200px) {
    section.partnerzy {
        flex-direction: row;
        padding-top: 80px;
        justify-content: initial;
        &.row {
            margin: 0;
        }
        div.content {
            @include homepage-slides-left;

            .teleadres, .partnerzy {
                max-width: calc(705px - 4rem);
                
                @media screen and (min-width: 1400px) {
                    max-width: calc(705px);
                }
            }

            div.teleadres {
                flex-direction: row;
                div.kontakt {
                    margin: initial;
                }
                div.kostki {
                    div {
                        padding: 25px 30px;
                    }
                }
            }
            h2.title {
                font-size: 28px;
                line-height: 22px;
                margin-bottom: 50px;
                position: relative;
                &::after {
                    width: 200px;
                }
            }
            div.partnerzy {
                h2.title::after {
                    width: 75%;
                    
                    @media screen and (min-width: 1400px) {
                        width: 80%;
                    }
                }
            }
        }
        div.map {
            height: initial;
            background-color: #ffffff;
            

            @include homepage-slides-right;
        }
    }
}


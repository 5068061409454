

section.uslugi {
    background-color: $main-bg-color;
    width: 100%;
    
    div.head {
        background-position: top;
        background-size: cover;
        position: relative;

        @media screen and (min-width: 1200px) {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }

        @include hero-height;

        div {
            position: absolute;
            bottom: -3px;
            margin-bottom: 0!important;
            left: 50%;
            transform: translateX(-50%);

            @include container-restriction;
            
            @media screen and (min-width: 768px) {
                margin-bottom: 0!important;
                // width: fit-content;
                width: initial;
                left: 20px;
                transform: initial;
            }

            @media screen and (min-width: 1200px) {
                left: initial;
                transform: initial;
                @include container-restriction;
            }

            h1 {
                font-family: 'Montserrat';
                font-size: 47px;
                letter-spacing: -2px;
                color: #fff;
                font-weight: 700;
                padding-bottom: 20px;
                margin-bottom: 0;
                width: fit-content;

                @media screen and (min-width: 768px) {
                    font-size: 77px;
                    padding-bottom: $levelMargin;
                }
            }
        }
    }
    div.content {
        display: flex;
        flex-direction: column;
        width: calc(min(100%, 1330px));
        
        @media screen and (min-width: 576px) {
            padding: 00.9375rem 20px 0.9375rem;
        }

        @media screen and (min-width: 1200px) {
            margin: 90px auto;
            padding: 0;
        }

        @include container-restriction;

        .wycen {
            margin: 20px 0;
        }
        div.level {
            display: flex;                
            flex: 1;

            @media screen and (min-width: 1200px) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            div.left, div.right {
                display: flex;
            }

            div.img {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: left;
                width: 100%;
                height: 100%;
                &.funkcje {
                    height: 300px;
                    background-position: center;
                    background-size: 90%;
                }
            }
            div.right {
                &.wentylacja {
                    flex-direction: column;
                    justify-content: space-between;
                }
                div.kafelki {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 100%;
                    flex-direction: column;

                    @media screen and (min-width: 992px) {
                        flex-direction: row;
                        align-items: initial;
                    }

                    @media screen and (min-width: 1200px) {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                    }

                    div {
                        height: 115px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0 30px;
                        width: 100%;
                        
                        @media screen and (min-width: 576px) {
                            height: 150px;
                        }
                        
                        @media screen and (min-width: 992px) {
                            margin-top: 20px;
                            width: auto;
                        }



                        &:nth-child(1) {
                            border-top-left-radius: 40px;
                        }
                        &:nth-child(2) {
                            border-bottom-right-radius: 40px;
                        }
                        span {
                            display: block;
                            width: fit-content;
                            font-size: 26px;
                            line-height: 25px;
                            color: #ffffff;
                            font-weight: 700;
                            font-family: "Montserrat";
                            text-align: center;
                        }
                    }
                }
                &.dlugi {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                }
            } 
            &>div.text {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            div.column {
                display: flex;
                flex-direction: column;
            }   

            &.wycen {
                width: 100%;
                display: flex;
                justify-content: center;
            }


            &.pompy_ciepla {
                display: flex;
                justify-content: space-between;
            }

            @media screen and (min-width: 1200px) {
                div.right {
                    div.img {
                        padding-left: 20px;
                    }
                }
            }

        }

        @media screen and (min-width: 768px) {
            div.wycen {
                img.wycen {
                    width: 336px; 
                }
            }
        }

        div.instalacje_p1 {
            h2 {
                @include first-section-header;
            }
        }
        div.klimatyzacja_p1_p3, div.fotowoltaika_p1, div.samochody_p2 {
            h2 {
                @include first-section-header;
                margin-top: 20px;
                
                @media screen and (min-width: 1200px) {
                    margin-top: initial;
                    margin-bottom: $levelMargin;
                }
            }
        }
        div.instalacje-gazowe_p2 {
            h2 {
                font-size: 34px;
                letter-spacing: -1px;
                color: #ffffff;
                font-weight: 600;
                font-family: "Montserrat";
                text-transform: uppercase;
                text-align: left;
            }
        }
        div.energia-wiatrowa, div.klimatyzacja_p2, div.wentylacja, div.fotowoltaika_p2, div.samochody_p3, div.instalacje_p2, div.kotly-gazowe, div.ogrzewanie_p2_p3 {
            h2 {
                @include first-section-header;
                margin-top: 20px;
                
                @media screen and (min-width: 1200px) {
                    margin-top: initial;
                }
            }
        }
        div.samochody_p4 {
            h2 {
                font-size: 34px;
                letter-spacing: -1px;
                color: #ffffff;
                font-weight: 600;
                font-family: "Montserrat";
                text-align: left;
                margin-bottom: 20px;
            }
            // width: 590px;
            p {
                margin-bottom: 0px;
                line-height: 40px;
            }
        }
        div.fotowoltaika_p3 {
            font-size: 33px;
            color: #ffffff;
            font-weight: 600;
            font-family: "Montserrat";
            text-align: left;
        }
        div.kafelki {
            @media screen and (min-width: 768px) {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                margin-top: initial;

                div {
                    width: 217px;
                    height: 202px;
                }
            }
        }
        div.text {
            font-family: "Lato";

            @media screen and (min-width: 1200px) {
                padding-bottom: 40px;
                text-align: justify;
                padding: initial;
            }

            &.kafelki:not(.text), &.ogrzewanie_p1 {
                p {
                    font-size: 1.125rem;
                    letter-spacing: -1px;
                    color: #ffffff;
                    font-weight: 500;
                    font-family: "Montserrat";
                    text-align: left;
                    
                    @media screen and (min-width: 1200px) {
                        font-size: 1.125rem;
                        // line-height: 60px;
                    }
                }
            }
            > span {
                font-size: 18px;
                color: #ddd;
                font-weight: 400;
                text-align: start;
                &.klimat {
                    font-size: 21px;
                    color: #0094f2;
                    font-weight: 900;
                    font-family: "Lato";
                    text-transform: uppercase;
                }
            }
            
            strong, b {
                font-weight: bold;
                
                span {
                    font-weight: bold;
                }
            }
        }

    }
}
section.uslugi div.content {
    div.img {
        background-position: center;
        background-size: cover;
    }
}


section.uslugi {

    div.kafelki:not(.text) {
        p {
            margin-bottom: 0;
        }

        @media screen and (min-width: 1200px) {
            height: 200px;
            // max-width: 430px;
        }

        div {
            padding: 0 1rem;

            @media screen and (min-width: 768px) {
                padding: 0 1.875rem;
            }

            @media screen and (min-width: 1200px) {
                width: 50%;
            }
        }
    }
    
    .uslugi-wrap {
        div.level {
            display: flex;

            @media screen and (min-width: 1200px) {
                flex-direction: row;
                flex-wrap: wrap; 
            }

            &.level_1 {
                @include level-1;

                h2 {
                    @include first-section-header;
                }

                div.right {
                    justify-content: center;
                    
                    @media screen and (min-width: 1200px) {
                        justify-content: flex-end;
                    }
                }
            }
            &.level_2 {
                display: grid;
                grid-template-columns: 100%;
                margin-bottom: $levelMargin;
                margin-top: $levelMargin;
                
                @media screen and (min-width: 768px) {
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: 2rem;
                }

                @media screen and (min-width: 1200px) {
                    column-gap: 5rem;
                }
                div.left {
                    justify-content: center;
                    align-items: center;
                    div.img {
                        height: 500px;

                        @media screen and (min-width: 1200px) {
                            max-width: 635px;
                            height: 100%;
                        }
                    }
                }
                div.right {
                    @media screen and (min-width: 1200px) {
                        // padding-top: 5rem;
                        // padding-bottom: 5rem;
                        max-width: 545px;
                        justify-content: flex-end;
                        margin-left: auto;
                    }
                    img {
                        width: 310px;
                        height: auto;
                        margin: 20px 0;
                        
                        @media screen and (min-width: 1200px) {
                            width: 100%;
                        }
                    }
                }
            }
            &.level_3 {
                display: flex;
                flex-direction: column;
                margin-top: $levelMargin;
                margin-bottom: $levelMargin;
                
                @media screen and (min-width: 768px) {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: 2rem;
                    row-gap: 2rem;
                }

                @media screen and (min-width: 1200px) {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    column-gap: 3.375rem;
                }

                div.blok {
                    margin: 10px 0;

                    @media screen and (min-width: 1200px) {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        img {
                            margin-bottom: 55px;
                            max-height: 5rem;
                        }
                    }
                    h2 {
                        font-size: 33px;
                        color: #ffffff;
                        font-weight: 600;
                        font-family: "Montserrat";
                        margin: 0.9375rem 0;

                        @media screen and (min-width: 1200px) {
                            margin: initial;
                            margin-bottom: 35px;
                        }
                    }
                    span {
                        font-size: 18px;
                        color: #ddd;
                        font-weight: 400;
                        font-family: "Lato";
                        text-align: justify;
                        display: inline-block;
                    }
                }
            }
            &.level_4 {
                margin-bottom: $levelMargin;
                margin-top: $levelMargin;

                @media screen and (min-width: 1200px) {
                    // margin-bottom: 170px !important;
                }
                div.rachunki {
                    display: flex;
                    flex-direction: column-reverse;

                    @media screen and (min-width: 1200px) {
                        display: flex;
                        flex-direction: row;
                        overflow: hidden;
                        width: 100%;
                    }
                    div.text {
                        padding: 2rem 0;

                        @media screen and (min-width: 1200px) {
                            padding: 2.34375rem 2.5rem 2.8125rem 2.65625rem;
                            background-color: #1b1b1b;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            width: 50%;
                            
                            img {
                                display: block;
                            }
                        }
                        
                        @media screen and (min-width: 1400px) {
                            padding: 4.6875rem 5rem 5.625rem 5.3125rem;
                        }

                        h2 {
                            font-size: 33px;
                            line-height: 54px;
                            color: #ffffff;
                            font-weight: 600;
                            font-family: "Montserrat";
                            margin: 0;
                            text-align: start;
                        }
                        span {
                            font-size: 18px;
                            color: #e0e0e0;
                            font-weight: 400;
                            font-family: "Lato";
                            text-align: left;
                            display: block;
                        }
                    }
                    img.img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                    div.img {
                        height: 400px;

                        @media screen and (min-width: 1200px) {
                            width: 50%;
                            background-position: center;
                            background-size: cover;
                            height: 100%;
                        }
                    }
                }
            }

            &.level_5 {
                margin-bottom: $levelMargin;

                @media screen and (min-width: 1200px) {
                    margin-bottom: 0;
                }

                &.moj-prad {
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    align-items: center;
                    justify-content: space-around;

                    @include container-restriction;
                    
                    p {
                        @include first-section-header;

                        text-align: center;
                        display: block;
                        font-family: "Open Sans";
                        width: fit-content;
                        
                        @media screen and (min-width: 1200px) {
                            width: 90%;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                    span {
                        display: block;
                        font-size: 1.125rem;
                        color: #ffffff;
                        font-weight: 400;
                        font-family: "Lato";
                        text-align: start;
                        justify-content: center;
                        
                        @media screen and (min-width: 1200px) {
                            text-align: center;
                            width: 90%;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
            }
            &.level_6 {
                margin-bottom: $levelMargin;

                @media screen and (min-width: 1200px) {
                    margin-bottom: 0;
                }

                &.wniosek {
                    display: flex;
                    flex-direction: column;
                    border-radius: 14px;
                    overflow: hidden;

                    div.blue {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        margin: 0.9375rem 0;

                        @media screen and (min-width: 768px) {
                            flex-direction: row;
                        }

                        @media screen and (min-width: 1200px) {
                            margin: 0;
                            height: 75px;
                            cursor: pointer;

                            span {   
                                font-size: 27px;
                                line-height: 3rem;
                            }
                        }
                        div {
                            font-size: 27px;
                            color: #ffffff;
                            font-family: "Open Sans";
                            // text-align: center;
                            padding: 10px 30px;
                            &.blok_3 {
                                flex: 1 1 auto
                            }
                        }
                    }
                    div.bottom {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        background-color: #1b1b1b;

                        @media screen and (min-width: 1200px) {
                            flex-direction: row;
                            height: 400px;
                        }
                        div.text {
                            font-size: 18px;
                            color: #ffffff;
                            font-weight: 500;
                            font-family: "Lato";
                            
                            @media screen and (min-width: 1200px) {
                                padding: 0 30px;
                            }
                        }
                        div.img {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 0.9375rem 0;

                            @media screen and (min-width: 1200px) {
                                margin: initial;
                            }
                            img {
                                display: block;
                                height: 264px;
                                width: 323px;
                            }
                        }                   
                    }
                }
            }
            &.level_7 {
                margin-bottom: $levelMargin;

                @media screen and (min-width: 1200px) {
                    margin-bottom: 0;
                }

                &.czekaj {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    p {
                        font-size: 33px;
                        color: #ffffff;
                        font-weight: 800;
                        font-family: "Open Sans";
                    }
                    span {
                        font-size: 18px;
                        color: #ffffff;
                        font-weight: 400;
                        font-family: "Lato";
                        text-align: center;
                    }
                    img {
                        margin-top: $levelMargin;
                    }
                }
            }
            .wycen {
                height: auto;
            }
        }
    }


}
section.realizacje {
    background-color: $main-bg-color;
    div.content {
        display: flex;
        flex-direction: column;
        margin: 50px auto;
        
        @include container-restriction;

        .title {
            // line-height: 25px;
            color: #ffffff;
            text-align: center;
            font-family: "Montserrat";
            padding: 1rem;
            border: 1px solid white;
            text-transform: uppercase;
            border-top-left-radius: 0.9375rem;
            border-bottom-right-radius: 0.9375rem;
            margin-bottom: 2rem;
            
            @media screen and (min-width: 1200px) {
                padding: 30px;
            }
            
            h2 {
                font-size: 1.8rem;
                margin-bottom: 0;
                font-weight: 600;
                
                @media screen and (min-width: 768px) {
                    font-size: 2rem;
                }
                
                @media screen and (min-width: 1200px) {
                    font-size: 1.8rem;
                }
                
                @media screen and (min-width: 1400px) {
                    font-size: 2rem;
                }
                
            }
        }
        
        div.gallery {
            display: grid;
            grid-template-columns: 1fr;
            justify-content: flex-start;
            margin-bottom: 100px;
            row-gap: 2rem;
            column-gap: 2rem;
            
            @media screen and (min-width: 576px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (min-width: 768px) {
                grid-template-columns: repeat(3, 1fr);
            }
            
            @media screen and (min-width: 1200px) {
                grid-template-columns: repeat(4, 1fr);
            }

            .img {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    aspect-ratio: 1;
                }
            }
        }
    }
}